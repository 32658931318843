import React from 'react';
import { Box, Button, Link, ListItemText, Menu, MenuItem } from '@mui/material';

import { elsewhere } from '@styles/theme';
import { elementClicked } from '@utils/sendEvent';

const MenuList = ({
  userStore,
  id,
  open,
  onClose,
  anchorEl,
  items,
  isDesktop,
  anchorOrigin,
  transformOrigin
}: {
  userStore?: any;
  id: string;
  open: boolean;
  onClose: () => void;
  anchorEl: any;
  items: any[];
  isDesktop: boolean;
  anchorOrigin?: any;
  transformOrigin?: any;
}) => (
  <Menu
    id={id}
    open={open}
    onClose={onClose}
    anchorEl={anchorEl}
    marginThreshold={0}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    disableScrollLock
    PaperProps={
      !isDesktop && {
        style: {
          width: '60%',
          maxWidth: '260px',
          left: 0,
          right: 0,
          top: 10
        }
      }
    }
  >
    {items.map((item, index) =>
      item.isButton ? (
        <Box
          sx={{
            width: '100%',
            pl: '12px',
            pb: '4px',
            pt: '12px',
            pr: '12px',
            mt: '4px',
            borderTop: '1px solid #EBE8F1'
          }}
        >
          <Button
            onClick={() => {
              item.onClick && item.onClick(userStore);
              elementClicked({
                actionOutcome: 'INTERNALLINK',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'link',
                  location: 'nav bar',
                  name: item.title,
                  position: 'nav bar',
                  text: item.title
                }
              });
            }}
            key={index}
            variant="contained"
            size="medium"
            sx={{ width: '100%' }}
            href={item.href}
          >
            {item.title}
          </Button>
        </Box>
      ) : (
        <Link
          underline="none"
          key={index}
          href={item.href}
          onClick={() => {
            item.onClick && item.onClick(userStore);
            if (item.onClick || item.href) {
              elementClicked({
                actionOutcome: 'INTERNALLINK',
                outboundUrl: 'null',
                webElement: {
                  elementType: 'link',
                  location: 'nav bar',
                  name: item.title,
                  position: 'nav bar',
                  text: item.title
                }
              });
            }
          }}
        >
          <MenuItem
            key={index}
            disabled={!item.href && !item.onClick && !item.isLoading}
            sx={{
              transition: 'opacity 0.2s ease-in-out'
            }}
          >
            <ListItemText
              sx={{
                fontWeight: elsewhere.fontWeight.book,
                color: elsewhere.color['gray-7']
              }}
              primary={item.title}
            />
          </MenuItem>
        </Link>
      )
    )}
  </Menu>
);
export default MenuList;
